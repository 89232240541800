import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-on-the-dashboard"
    }}>{`What is on the Dashboard`}</h1>
    <p><strong parentName="p">{`Here is an overview of widgets that are visible on the dashboard.`}</strong></p>
    <h3 {...{
      "id": "resources-with-backup"
    }}>{`Resources with Backup`}</h3>
    <p>{`Contains the number of resources with backup enabled and disabled. Diagram illustrates percentage values.
`}<img alt="Dashboard widgets" src={require("../assets/widgets/widgets_1.png")} /></p>
    <h3 {...{
      "id": "data-under-protection"
    }}>{`Data Under Protection`}</h3>
    <p>{`Shows volume of data under protection and without protection. Diagram illustrates those values in percents.
`}<img alt="Dashboard widgets" src={require("../assets/widgets/widgets_2.png")} /></p>
    <h3 {...{
      "id": "policy-compliance"
    }}>{`Policy Compliance`}</h3>
    <p>{`Illustrates the number resources that were backed up within SLA time set up in Policy in relation to total amount of backed up resources. Diagram shows the percentage of compliant resources.
`}<img alt="Dashboard widgets" src={require("../assets/widgets/widgets_3.png")} /></p>
    <h3 {...{
      "id": "available-reports"
    }}>{`Available Reports`}</h3>
    <p>{`This section allows to download reports about: backup events (previous and the current month),
resources (actual state) and orphaned snapshots.
`}<img alt="Dashboard widgets" src={require("../assets/widgets/widgets_5.png")} /></p>
    <h3 {...{
      "id": "scan-status"
    }}>{`Scan Status`}</h3>
    <p>{`This widget contains status of last scan and a button that triggers another scanning of all resources.
`}<img alt="Dashboard widgets" src={require("../assets/widgets/widgets_6.png")} /></p>
    <h3 {...{
      "id": "unprotected-resources"
    }}>{`Unprotected resources`}</h3>
    <p>{`Shows a total number of resources without backup enabled and contains a button that redirects to Resources view with unprotected estate already filtered against protected resources.
`}<img alt="Dashboard widgets" src={require("../assets/widgets/widgets_7.png")} /></p>
    <h3 {...{
      "id": "upcoming-events"
    }}>{`Upcoming Events`}</h3>
    <p>{`Shows a list of scheduled backups with a corresponding backup plan, start time and number of resources.
`}<img alt="Dashboard widgets" src={require("../assets/widgets/widgets_4.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      